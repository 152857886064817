export default function Portfolio() {
  return {
    displayItems(id) {
      const div = document.getElementById("p-" + id);
      div.classList.toggle("h-0");
      div.classList.toggle("max-h-[3600px]");
      div.classList.toggle("md:grid");
      div.classList.toggle("mt-24");
    },
  };
}
